<template>
  <v-data-table
    :headers="headers"
    :items="list"
    :search="search"
    sort-by="calories"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <!-- <v-toolbar-title> -->
        <v-text-field
          v-model="search"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
        <!-- </v-toolbar-title> -->
        <!-- <v-divider class="mx-4" inset vertical></v-divider> -->
        <v-spacer></v-spacer>
        <v-dialog
          v-model="dialog"
          max-width="700"
        >
          <template v-slot:activator="{ on, attrs }">
            <!-- <div class="d-flex justify-space-between"> -->
            <!-- <v-text-field v-model="search" label="Search" single-line hide-details></v-text-field> -->
            <v-btn
              color="primary"
              dark
              class="mb-2"
              v-bind="attrs"
              v-on="on"
              @click="close"
            >
              เพิ่มแพ็กเกจเติมเงิน
            </v-btn>
            <!-- </div> -->
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>

                  <v-col
                    cols="12"
                    sm="12"
                    md="6"
                  >
                    <v-row>
                      <v-col
                        cols="12"
                        sm="12"
                        md="12"
                      >
                        <v-text-field
                          v-model="editedItem.package_name"
                          label="ชื่อแพ็กเกจ"
                        ></v-text-field>

                        <small
                          class="validate-err"
                          v-for="(err,index) in v$.editedItem.package_name.$silentErrors"
                          v-if="v$.editedItem.package_name.$error">{{ index > 0 ? ', ' : '' }}
                          {{ err.$message }}</small>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="12"
                        md="12">
                        <v-text-field
                          type="number"
                          v-model="editedItem.package_price"
                          label="ค่าแพ็กเกจ(บาท)"
                        ></v-text-field>
                        <small
                          class="validate-err"
                          v-for="(err,index) in v$.editedItem.package_price.$silentErrors"
                          v-if="v$.editedItem.package_price.$error">{{ index > 0 ? ', ' : '' }}
                          {{ err.$message }}</small>
                      </v-col>

                      <v-col
                        cols="12"
                        sm="12"
                        md="12">
                        <v-text-field
                          type="number"
                          v-model="editedItem.package_item_amt"
                          label="จำนวน(ชิ้น)"
                        ></v-text-field>
                        <small
                          class="validate-err"
                          v-for="(err,index) in v$.editedItem.package_item_amt.$silentErrors"
                          v-if="v$.editedItem.package_item_amt.$error">{{ index > 0 ? ', ' : '' }}
                          {{ err.$message }}</small>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="12"
                        md="12">
                        <v-textarea
                          v-model="editedItem.package_detail"
                          label="รายละเอียดแพ็กเกจ"
                        ></v-textarea>
                        <small
                          class="validate-err"
                          v-for="(err,index) in v$.editedItem.package_detail.$silentErrors"
                          v-if="v$.editedItem.package_detail.$error">{{ index > 0 ? ', ' : '' }}
                          {{ err.$message }}</small>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col
                    cols="12"
                    sm="12"
                    md="6"
                  >
                    <v-row>
                      <v-col
                        cols="12"
                        sm="12"
                        md="12"
                      >
                        <v-text-field
                          v-model="editedItem.code_promotion"
                          label="รหัสโปรโมชั่น"
                        ></v-text-field>
                      </v-col>

                      <v-col
                        cols="12"
                        sm="12"
                        md="12"
                      >
                        <DatePicker
                          v-model="editedItem.start_promotion"
                          placeholder="วันที่เริ่มต้น"
                          value-type="format"
                          format="YYYY-MM-DD"
                          label="วันที่เริ่มต้น"
                        ></DatePicker>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="12"
                        md="12"
                      >
                        <DatePicker
                          v-model="editedItem.end_promotion"
                          placeholder="วันที่สิ้นสุด"
                          value-type="format"
                          format="YYYY-MM-DD"
                          label="วันที่สิ้นสุด"
                        ></DatePicker>
                      </v-col>

                      <v-col
                        cols="12"
                        sm="12"
                        md="12"
                      >
                        <v-text-field
                          type="number"
                          v-model="editedItem.percent_promotion"
                          label="ส่วนลด(%)"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="12"
                        md="12"
                      >
                        <v-text-field
                          type="number"
                          v-model="editedItem.installment_month"
                          label="แบ่งผ่อนชำระ/เดือน"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="error"
                text
                @click="close"
              >
                Cancel
              </v-btn>
              <v-btn
                color="primary"
                text
                @click="save"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="dialogDelete"
          max-width="600px"
        >
          <v-card>
            <v-card-title class="text-h5 mb-5">
              Are you sure you want to delete this item?
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="error"
                outlined
                @click="closeDelete"
              >
                Cancel
              </v-btn>
              <v-btn
                color="primary"
                @click="deleteItemConfirm"
              >
                OK
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.index="{ item }">
      {{ list.indexOf(item) + 1 }}
    </template>
    <template v-slot:item.status="{ item }">
      <v-checkbox
        value="Y"
        v-model="item.package_status"
        @click="updateStatus(item.id)"
      >
      </v-checkbox>

      <!--      <v-div v-if="item.package_status == 'N'">-</v-div>-->
      <!--      return '<label class="switch">\n' +-->
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        {{ icons.mdiPencil }}
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
      >
        {{ icons.mdiDelete }}
      </v-icon>

      <!--      <v-text v-if="item.package_status == 'N'">-</v-text>-->
    </template>
    <template v-slot:no-data>
      <!-- <v-btn
        color="primary"
        @click="initialize"
      >
        Reset
      </v-btn> -->
    </template>
  </v-data-table>
</template>

<script>
import {mdiPencil, mdiDelete} from '@mdi/js'
// import config from '../../../config'
import instance from '../../../axios.service'
import instance_package_cash from '../../../services/packageCash'
import DatePicker from 'vue2-datepicker'
import Swal from "sweetalert2";
import {email, minLength, required} from "@vuelidate/validators";
import {useVuelidate} from "@vuelidate/core";

export default {
  components: {DatePicker},
  setup() {
    return {
      v$: useVuelidate(),
      icons: {
        mdiPencil,
        mdiDelete,
      },
    }
  },
  data: () => ({
    list: [],
    dialog: false,
    dialogDelete: false,
    search: '',
    headers: [
      {
        text: 'ลำดับ',
        value: 'index', sortable: false
      },
      {text: 'ชื่อแพ็กเกจ', value: 'package_name'},
      {text: 'ราคาแพ็กเกจ', value: 'package_price'},
      {text: 'จำนวน(บาท)', value: 'package_item_amt'},
      {text: 'สถานะ (เปิด/ปิด)', value: 'status', sortable: false},
      {text: 'แก้ไขข้อมูล', value: 'actions', sortable: false},
    ],
    desserts: [],
    editedIndex: -1,
    editedItem: {
      package_name: '',
      code_promotion: '',
      package_price: '',
      package_price_all: '',
      package_detail: '',
      start_promotion: '',
      end_promotion: '',
      percent_promotion: 0,
      installment_month: 0,
      package_status: 'N',
      package_item_amt: '',
      package_package_price: 0,
      price_pe_item: 0,
    },
    defaultItem: {
      package_name: '',
      code_promotion: '',
      package_price: '',
      package_price_all: '',
      package_detail: '',
      start_promotion: '',
      end_promotion: '',
      percent_promotion: 0,
      installment_month: 0,
      package_status: 'N',
      package_item_amt: '',
      package_package_price: 0,
      price_pe_item: 0,
    },
  }),
  validations() {
    return {
      editedItem: {
        package_detail: {required},
        package_item_amt: {required},
        package_name: {required},
        package_price: {required},
      }
    }
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'เพิ่ม แพ็กเกจเติมเงิน' : 'แก้ไข แพ็กเกจเติมเงิน'
    },
  },

  watch: {
    // dialog(val) {
    //   val || this.close()
    // },
    // dialogDelete(val) {
    //   val || this.closeDelete()
    // },
  },

  created() {
    // this.initialize()
  },
  mounted() {
    // this.getUser()
    this.getPackageCash()
  },

  methods: {

    updateStatus(id) {
      this.$store.state.isLoading = true
      instance_package_cash.packageUpdateStatus(id).then(res => {
        Swal.fire({
          icon: 'success',
          title: res.data.message,
          showConfirmButton: false,
          timer: 1500
        })
        this.$store.state.isLoading = false

      }).catch(err => {
        Swal.fire({
          icon: 'error',
          title: 'เกิดข้อผิดพลาด !!',
          text: err.message,
        })
        this.$store.state.isLoading = false
      });
    },
    changeStatus() {
      // { url('admin/package_credit_updateStatus/:id') }}"
    },
    editItem(item) {
      this.v$.$reset();
      this.editedIndex = this.list.indexOf(item)
      this.editedItem = {...item}
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.list.indexOf(item)
      this.editedItem = {...item}
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      this.$store.state.isLoading = true
      instance_package_cash.destroy(this.editedItem).then(res => {
        setTimeout(function () {
          Swal.fire({
            icon: 'success',
            title: res.data.message,
            showConfirmButton: false,
            timer: 1500
          })
        }, 150)
        this.getPackageCash()
        this.closeDelete()
      }).catch(err => {
        Swal.fire({
          icon: 'error',
          title: 'เกิดข้อผิดพลาด !!',
          text: err.message,
        })
        this.$store.state.isLoading = false

      })
    },

    close() {
      this.v$.$reset();
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = {...this.defaultItem}
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = {...this.defaultItem}
        this.editedIndex = -1
      })
    },
    async save() {
      const isFormCorrect = await this.v$.$validate()
      if (!isFormCorrect) return
      this.$store.state.isLoading = true

      if (this.editedIndex > -1) {
        instance_package_cash.update(this.editedItem)
          .then(res => {
            setTimeout(function () {
              Swal.fire({
                icon: 'success',
                title: res.data.message,
                showConfirmButton: false,
                timer: 1500
              })
            }, 150)
            this.getPackageCash()
            this.close()
          }).catch(err => {
          Swal.fire({
            icon: 'error',
            title: 'เกิดข้อผิดพลาด !!',
            text: err.message,
          })
          this.$store.state.isLoading = false

        });
      } else {
        instance_package_cash.store(this.editedItem)
          .then(res => {
            setTimeout(function () {
              Swal.fire({
                icon: 'success',
                title: res.data.message,
                showConfirmButton: false,
                timer: 1500
              })
            }, 150);
            this.getPackageCash()
            this.close()
          }).catch(err => {
          Swal.fire({
            icon: 'error',
            title: 'เกิดข้อผิดพลาด !!',
            text: err.message,
          })
          this.$store.state.isLoading = false

        });
      }
    },
    getPackageCash() {
      this.$store.state.isLoading = true
      var data = {
        search: '',
        date: '',
        status: '',
        size: 9999999,
        page: 1
      }

      instance_package_cash.getManagePackage(data).then(res => {
        this.list = res.data.data.data;
        this.$store.state.isLoading = false
      }).catch(err => {
        this.$store.state.isLoading = false
      });
    },
  },
}
</script>

<style scoped>
hr {
  background-color: #e5e5e5;
  height: 1px;
  border: none;
}
</style>
